import React from 'react'
import { connect } from 'react-redux'
import { userLogin } from 'react-admin'
import classnames from 'classnames'
import { Card, makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#F2F2F2',
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
      backgroundColor: '#F2F2F2',
      boxShadow: 'none',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
    form: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonGoogleContainer: {
      textDecoration: 'none',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    buttonGoogle: {
      margin: '1em auto 2em',
      padding: '.2em .5em',
      textTransform: 'none',
    },
    logoGoogle: {
      width: 20,
      height: 20,
      backgroundColor: '#fff',
      padding: '.3em',
      borderRadius: 3,
    },
  }),
  { name: 'RaLogin' },
)

const MyLoginPage = props => {
  const { className } = props
  const classes = useStyles(props)

  if (props.location.search.startsWith('?token=')) {
    const token = props.location.search.replace('?token=', '')
    props.userLogin(token)
  }

  return (
    <div className={classnames(classes.main, className)}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <img src="/logo_isdin.png" alt="isdin" />
        </div>
        <form className={classes.form}>
          <a
            className={classes.buttonGoogleContainer}
            href={`${process.env.REACT_APP_API_URL}/auth/sso-login`}
          >
            <Button
              className={classes.buttonGoogle}
              startIcon={(
                <img
                  className={classes.logoGoogle}
                  src="/google.png"
                  alt="google"
                />
              )}
              variant="contained"
              color="primary"
            >
              Sign in with Google
            </Button>
          </a>
        </form>
      </Card>
    </div>
  )
}

export default connect(undefined, { userLogin })(MyLoginPage)
